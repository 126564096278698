// src/components/UploadReportModal.js
import React, { useState } from 'react';
import { Modal, Button, TextField, MenuItem } from '@mui/material';
import MDButton from 'components/MDButton';

const UploadReportModal = ({ open, handleClose }) => {
    const [rpdnId, setRpdnId] = useState('');
    const [reportType, setReportType] = useState('');
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = () => {
        // Handle the file upload logic here
        console.log('RPDN ID:', rpdnId);
        console.log('Report Type:', reportType);
        console.log('File:', file);
        // Close the modal after submission
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{
                padding: '20px',
                backgroundColor: 'white',
                borderRadius: '8px',
                maxWidth: '500px',
                margin: 'auto',
                top: '50%',
                transform: 'translateY(-50%)',
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <h2>Upload Report</h2>
                <TextField
                    label="RPDN ID"
                    value={rpdnId}
                    onChange={(e) => setRpdnId(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    select
                    label="Report Type"
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    <MenuItem value="detainee">Detainee Intake</MenuItem>
                    <MenuItem value="conference">Case Conference</MenuItem>
                    <MenuItem value="update">Case Update</MenuItem>
                    <MenuItem value="review">Case Review</MenuItem>
                    <MenuItem value="pdss">PDSS</MenuItem>
                </TextField>
                <input type="file" accept=".pdf, .doc, .docx" onChange={handleFileChange} />
                <div style={{ marginTop: '20px' }}>
                    <MDButton variant="contained" color="success" onClick={handleSubmit}>
                        Upload
                    </MDButton>
                    <MDButton variant="outlined" color="warning" onClick={handleClose} style={{ marginLeft: '10px' }}>
                        Cancel
                    </MDButton>
                </div>
            </div>
        </Modal>
    );
};

export default UploadReportModal;