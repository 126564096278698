import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import ComplexStatisticsCard from "utilities/Cards/StatisticsCards/ComplexStatisticsCard";
import { Bar, Pie } from "react-chartjs-2";
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "context/auth";
import {
	getActiveLocationsCount,
	getTotalClinics,
	getTotalMembers,
	getGenderDistribution,
	getPWDDistribution,
	getPlacesOfArrestData,
	getCustodialCentersData,
	getDateOfArrestData,
	getIncomeLevelData,
	getReviewWeeklySummary,
	getDetaineeWeeklySummary,
	getConferenceWeeklySummary,
} from "services/analyticsService";
import YearPicker from "components/year-picker";
import StatePicker from "components/State-picker";
import { Card, Divider, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from "chart.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
);

const getRandomColor = () => {
	const letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

// Common chart options
const commonChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "bottom",
			labels: {
				boxWidth: 12,
				padding: 15,
			},
		},
		tooltip: {
			callbacks: {
				label: (context) => {
					const value = context.raw || 0;
					return ` ${value.toLocaleString()}`;
				},
			},
		},
	},
};

const barChartOptions = {
	...commonChartOptions,
	scales: {
		y: {
			beginAtZero: true,
			ticks: {
				callback: (value) => value.toLocaleString(),
			},
		},
	},
};

const horizontalBarChartOptions = {
	...barChartOptions,
	indexAxis: "y",
	scales: {
		x: {
			beginAtZero: true,
			ticks: {
				callback: (value) => value.toLocaleString(),
			},
		},
	},
};

function Analytics() {
	const { user } = useAuth();
	const [clinicData, setClinicData] = useState(0);
	const [locationData, setLocationData] = useState(0);
	const [memberData, setMemberData] = useState(0);
	const [year, setYear] = useState(new Date().getFullYear());
	const [state, setState] = useState("");
	const [loading, setLoading] = useState(true);

	const [genderDistributionMembers, setGenderDistributionMembers] = useState({});
	const [pwdDistributionMembers, setPWDDistributionMembers] = useState({});
	const [placesOfArrestData, setPlacesOfArrestData] = useState({});
	const [custodialCentersData, setCustodialCentersData] = useState({});
	const [dateOfArrestData, setDateOfArrestData] = useState({});
	const [incomeLevelData, setIncomeLevelData] = useState({});
	const [weeklyReviewData, setWeeklyReviewData] = useState({});
	const [weeklyDetaineeData, setWeeklyDetaineeData] = useState({});
	const [weeklyConferenceData, setWeeklyConferenceData] = useState({});

	const setChartData = (data, label = "") => {
		if (data && data.labels && data.values) {
			return {
				labels: data.labels,
				datasets: [
					{
						label: label,
						data: data.values,
						backgroundColor: data.values.map(() => getRandomColor()),
					},
				],
			};
		}
		return {
			labels: [],
			datasets: [
				{
					label: label,
					data: [],
					backgroundColor: [],
				},
			],
		};
	};

	const fetchData = useCallback(async () => {
		setLoading(true);
		try {
			const fallbackState = state || "default-state";
			const fallbackYear = year || new Date().getFullYear();

			const [
				cRes,
				lRes,
				mRes,
				genderRes,
				pwdRes,
				placesRes,
				custodialRes,
				dateRes,
				incomeRes,
				weeklyRevRes,
				weeklyDetRes,
				weeklyConfRes,
			] = await Promise.all([
				getTotalClinics(fallbackYear, fallbackState),
				getActiveLocationsCount(fallbackYear, fallbackState),
				getTotalMembers(fallbackYear, fallbackState),
				getGenderDistribution(fallbackYear, fallbackState),
				getPWDDistribution(fallbackYear, fallbackState),
				getPlacesOfArrestData(fallbackYear, fallbackState),
				getCustodialCentersData(fallbackYear, fallbackState),
				getDateOfArrestData(fallbackYear, fallbackState),
				getIncomeLevelData(fallbackYear, fallbackState),
				getReviewWeeklySummary(),
				getDetaineeWeeklySummary(),
				getConferenceWeeklySummary(),
			]);

			setLocationData(lRes.data?.activeLocations || 0);
			setClinicData(cRes?.data?.totalClinics || 0);
			setMemberData(mRes?.data?.totalMembers || 0);

			setGenderDistributionMembers(setChartData(genderRes.data));
			setPWDDistributionMembers(setChartData(pwdRes.data));
			setPlacesOfArrestData(setChartData(placesRes.data, "Places of Arrest"));
			setCustodialCentersData(
				setChartData(custodialRes.data, "Custodial Centers"),
			);
			setDateOfArrestData(setChartData(dateRes.data, "Arrests by Date"));
			setIncomeLevelData(setChartData(incomeRes.data, "Income Distribution"));

			// Weekly data with specific labels
			setWeeklyReviewData(setChartData(weeklyRevRes.data, "Weekly Reviews"));
			setWeeklyDetaineeData(setChartData(weeklyDetRes.data, "Weekly Detainees"));
			setWeeklyConferenceData(
				setChartData(weeklyConfRes.data, "Weekly Conferences"),
			);
		} catch (error) {
			console.error("Dashboard data fetch error:", error);
		} finally {
			setLoading(false);
		}
	}, [year, state]);

	useEffect(() => {
		fetchData();
	}, [year, state, fetchData]);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				display: true,
				position: "top",
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						return ` ${context.dataset.label}: ${context.raw}`;
					},
				},
			},
		},
		scales: {
			y: {
				beginAtZero: true,
			},
		},
	};

	const ChartCard = ({ title, chart: Chart, data, options, height = 300 }) => (
		<Card sx={{ p: 3 }}>
			<MDTypography variant="h6" fontWeight="medium" mb={2}>
				{title}
			</MDTypography>
			<MDBox height={height}>
				{data.labels.length > 1 ? (
					<Chart data={data} options={options} />
				) : (
					<MDBox
						display="flex"
						justifyContent="center"
						alignItems="center"
						height="100%"
					>
						<Typography variant="body1" color="textSecondary">
							No data available
						</Typography>
					</MDBox>
				)}
			</MDBox>
		</Card>
	);

	if (loading) {
		return (
			<MDBox display="flex" justifyContent="center" alignItems="center">
				<MDTypography variant="h6">
					<MDBox
						display="flex"
						justifyContent="center"
						alignItems="center"
						minHeight="100vh"
					>
						<CircularProgress color="info" />
					</MDBox>
					Loading...
				</MDTypography>
			</MDBox>
		);
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox my={1} px={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<YearPicker selectedYear={year} onYearChange={setYear} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<StatePicker selectedState={state} onStateChange={setState} />
					</Grid>
				</Grid>
				<Divider />
			</MDBox>

			<Grid container spacing={3} mt={3}>
				{/* Statistics Cards */}
				<Grid item xs={12} md={4}>
					<ComplexStatisticsCard
						color="success"
						icon="account_balance"
						title="LAW CLINICS"
						count={clinicData}
						percentage={{ color: "success", label: "Total number of clinics" }}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<ComplexStatisticsCard
						color="dark"
						icon="person"
						title="Members"
						count={memberData}
						percentage={{ color: "success", label: "Total number of members" }}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<ComplexStatisticsCard
						icon="location_on"
						title="Active Locations"
						count={locationData}
						percentage={{
							color: "success",
							label: "Total number of active locations",
						}}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ChartCard
						title="Gender Distribution of Members"
						chart={Pie}
						data={genderDistributionMembers}
						options={commonChartOptions}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ChartCard
						title="PWD Distribution of Members"
						chart={Pie}
						data={pwdDistributionMembers}
						options={commonChartOptions}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ChartCard
						title="Places of Arrest of Detainees"
						chart={Bar}
						data={placesOfArrestData}
						options={horizontalBarChartOptions}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ChartCard
						title="Custodial Center Detainees Number"
						chart={Bar}
						data={custodialCentersData}
						options={barChartOptions}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ChartCard
						title="Date of Arrest (Monthly)"
						chart={Bar}
						data={dateOfArrestData}
						options={barChartOptions}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<ChartCard
						title="Income Level of Detainees (₦)"
						chart={Bar}
						data={incomeLevelData}
						options={barChartOptions}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<MDBox>
						<Card sx={{ p: 10 }}>
							<MDTypography variant="h6" fontWeight="medium">
								Weekly Review Data
							</MDTypography>
							<Bar data={weeklyReviewData} options={chartOptions} />
						</Card>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6}>
					<MDBox>
						<Card sx={{ p: 10 }}>
							<MDTypography variant="h6" fontWeight="medium">
								Weekly Detainee Data
							</MDTypography>
							<Bar data={weeklyDetaineeData} options={chartOptions} />
						</Card>
					</MDBox>
				</Grid>
				<Grid item xs={12} md={6}>
					<MDBox>
						<Card sx={{ p: 10 }}>
							<MDTypography variant="h6" fontWeight="medium">
								Weekly Conference Data
							</MDTypography>
							<Bar data={weeklyConferenceData} options={chartOptions} />
						</Card>
					</MDBox>
				</Grid>
			</Grid>

			<Footer />
		</DashboardLayout>
	);
}

export default Analytics;
