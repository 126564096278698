import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Autocomplete,
    TextField,
    Box,
    Grid,
} from "@mui/material";
import dayjs from "dayjs";
import MDButton from "components/MDButton";
import { handleReportDownload } from "services/generateReportService";
import { toast } from "react-toastify";

const GenerateReport = () => {
    const [open, setOpen] = useState(false);
    const [year, setYear] = useState(dayjs().year());
    const [month, setMonth] = useState(dayjs().month() + 1);
    const [reportType, setReportType] = useState("M&E Report");
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleReportDownloadAction = async () => {
        try {
            setDownloadLoading(true);
            const url = reportType === "Justice Report" ? "/report/justice-report" : "/report/me-report";
            const filename = `${reportType.replace(/\s+/g, "_")}_${month}_${year}.${reportType === "Justice Report" ? "pdf" : "xlsx"}`;
            await handleReportDownload(url, filename);
            toast("Report Downloaded", { type: "success", autoClose: 3000 });
        } catch (error) {
            console.error('download error', error.message);
            toast(error.message, { type: "error", autoClose: 3000 });
        } finally {
            setDownloadLoading(false);
        }
    };

    return (
        <div>
            <MDButton
                variant="contained"
                color="light"
                onClick={handleOpen}
                sx={{ ml: 2 }}
            >
                Generate Report
            </MDButton>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle>Generate Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box sx={{ padding: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        value={year}
                                        onChange={(event, newValue) => setYear(newValue)}
                                        options={[...Array(10).keys()].map((i) => dayjs().year() - i)}
                                        renderInput={(params) => <TextField {...params} label="Year" />}
                                        getOptionLabel={(option) => option.toString()}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        value={month}
                                        onChange={(event, newValue) => setMonth(newValue)}
                                        options={Array.from({ length: 12 }, (_, i) => i + 1)}
                                        renderInput={(params) => <TextField {...params} label="Month" />}
                                        getOptionLabel={(option) => dayjs().month(option - 1).format("MMMM")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        value={reportType}
                                        onChange={(event, newValue) => setReportType(newValue)}
                                        options={["M&E Report", "Justice Report"]}
                                        renderInput={(params) => <TextField {...params} label="Report Type" />}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <MDButton
                        onClick={handleReportDownloadAction}
                        disabled={downloadLoading}
                        variant="contained"
                        color="info"
                    >
                        {downloadLoading ? "Generating report for download..." : `Download ${reportType}`}
                    </MDButton>
                    <MDButton
                        onClick={handleClose}
                        variant="outlined"
                        color="error"
                    >
                        Cancel
                    </MDButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default GenerateReport;
