"use client"

import { useState } from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material"
import MDButton from "components/MDButton"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { sendResetToken, verifyResetToken, resetPassword } from "services/userService"

function ForgotPasswordModal({ open, onClose }) {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [token, setToken] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errors, setErrors] = useState({})

    const steps = ["Email Verification", "Token Verification", "Reset Password"]

    const validateEmail = () => {
        const newErrors = {}
        if (!email.trim()) {
            newErrors.email = "Email is required"
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address"
            }
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const validateToken = () => {
        const newErrors = {}
        if (!token.trim()) {
            newErrors.token = "Token is required"
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const validatePassword = () => {
        const newErrors = {}
        if (!newPassword.trim()) {
            newErrors.newPassword = "New password is required"
        } else if (newPassword.length < 6) {
            newErrors.newPassword = "Password must be at least 6 characters"
        }
        if (!confirmPassword.trim()) {
            newErrors.confirmPassword = "Please confirm your password"
        } else if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match"
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleSubmitEmail = async () => {
        if (!validateEmail()) return

        setLoading(true)

        try {
            const data = await sendResetToken(email)
            console.log(data);
            toast.success(data.message, { autoClose: 3000 })
            setActiveStep(1)
        } catch (error) {
            toast.error(error.message || "Failed to send reset token. Please try again.", { autoClose: 3000 })
        } finally {
            setLoading(false)
        }
    }

    const handleVerifyToken = async () => {
        if (!validateToken()) return

        setLoading(true)

        try {
            const data = await verifyResetToken(email, token)
            toast.success(data.message, { autoClose: 3000 })
            setActiveStep(2)
        } catch (error) {
            toast.error(error.message || "Failed to verify token. Please try again.", { autoClose: 3000 })
        } finally {
            setLoading(false)
        }
    }

    const handleResetPassword = async () => {
        if (!validatePassword()) return

        setLoading(true)

        try {
            const data = await resetPassword(email, newPassword)
            toast.success(data.message, { autoClose: 5000 })
            resetForm()
            onClose()
            navigate("/")
        } catch (error) {
            toast.error(error.message || "Failed to reset password. Please try again.", { autoClose: 3000 })
        } finally {
            setLoading(false)
        }
    }

    const resetForm = () => {
        setActiveStep(0)
        setEmail("")
        setToken("")
        setNewPassword("")
        setConfirmPassword("")
        setErrors({})
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <MDTypography fontWeight="medium">
                    Forgot Password
                </MDTypography>
            </DialogTitle>
            <DialogContent>
                <MDBox mt={2} mb={3}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </MDBox>

                {activeStep === 0 && (
                    <MDBox component="form" role="form">
                        <MDTypography variant="body2" mb={2}>
                            Enter your email address and we'll send you a token to reset your password.
                        </MDTypography>
                        <TextField
                            label="Email Address"
                            type="email"
                            fullWidth
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                if (errors.email) {
                                    setErrors({ ...errors, email: "" })
                                }
                            }}
                            error={!!errors.email}
                            helperText={errors.email}
                            required
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0.5rem",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "info.main",
                                        borderWidth: 2,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "info.main",
                                },
                            }}
                        />
                    </MDBox>
                )}

                {activeStep === 1 && (
                    <MDBox component="form" role="form">
                        <MDTypography variant="body2" mb={2}>
                            Enter the token sent to your email address.
                            <br />
                        </MDTypography>
                        <TextField
                            label="Token"
                            fullWidth
                            value={token}
                            onChange={(e) => {
                                setToken(e.target.value)
                                if (errors.token) {
                                    setErrors({ ...errors, token: "" })
                                }
                            }}
                            error={!!errors.token}
                            helperText={errors.token}
                            required
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0.5rem",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "info.main",
                                        borderWidth: 2,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "info.main",
                                },
                            }}
                        />
                    </MDBox>
                )}

                {activeStep === 2 && (
                    <MDBox component="form" role="form">
                        <MDTypography variant="body2" mb={2}>
                            Enter your new password.
                        </MDTypography>
                        <MDBox mb={2}>
                            <TextField
                                label="New Password"
                                type="password"
                                fullWidth
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                    if (errors.newPassword) {
                                        setErrors({ ...errors, newPassword: "" })
                                    }
                                }}
                                error={!!errors.newPassword}
                                helperText={errors.newPassword}
                                required
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "0.5rem",
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "info.main",
                                            borderWidth: 2,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: "info.main",
                                    },
                                }}
                            />
                        </MDBox>
                        <TextField
                            label="Confirm New Password"
                            type="password"
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value)
                                if (errors.confirmPassword) {
                                    setErrors({ ...errors, confirmPassword: "" })
                                }
                            }}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            required
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "0.5rem",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "info.main",
                                        borderWidth: 2,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "info.main",
                                },
                            }}
                        />
                    </MDBox>
                )}
            </DialogContent>
            <DialogActions>
                <MDButton onClick={handleClose} color="secondary">
                    Cancel
                </MDButton>

                {activeStep === 0 && (
                    <MDButton onClick={handleSubmitEmail} color="info" disabled={loading}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Send Token"}
                    </MDButton>
                )}

                {activeStep === 1 && (
                    <MDButton onClick={handleVerifyToken} color="info">
                        Verify Token
                    </MDButton>
                )}

                {activeStep === 2 && (
                    <MDButton onClick={handleResetPassword} color="info" disabled={loading}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Reset Password"}
                    </MDButton>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default ForgotPasswordModal

