// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Clinics from "layouts/clinics";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import PasswordRequest from "layouts/authentication/password-request";
import SignUp from "layouts/authentication/sign-up";
import Members from "layouts/members";
import Report from "layouts/report";
import MemberProfile from "layouts/member";

// @mui icons
import Icon from "@mui/material/Icon";
import ClinicDetails from "layouts/clinic";
import CaseConferenceForm from "layouts/case-conference-form";
import DetaineeForm from "layouts/detainee-report-form";
import CaseReviewForm from "layouts/case-review-form";
import CaseConferenceView from "layouts/case-conference-view";
import CaseUploadForm from "layouts/case-upload-form";
import CaseUploadView from "layouts/case-upload-view";
import CaseFiles from "layouts/case-files";
import DetaineeReportView from "layouts/detainee-report-view";
import CaseFileDetails from "layouts/case-file-view";
import GroupDetails from "layouts/group";
import Analytics from "layouts/analytics";
import PdssReportForm from "layouts/pdss-report-form";
import RequestsPage from "layouts/requests";

export const routes = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <Icon fontSize="small">dashboard</Icon>,
		route: "/dashboard",
		component: <Dashboard />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Analytics",
		key: "analytics",
		icon: <Icon fontSize="small">analytics</Icon>,
		route: "/analytics",
		component: <Analytics />,
		protected: true,
		isAdmin: true,
	},
	{
		type: "collapse",
		name: "Members",
		key: "members",
		icon: <Icon fontSize="small">person_group</Icon>,
		route: "/members",
		component: <Members />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Clinics",
		key: "clinics",
		icon: <Icon fontSize="small">account_balance</Icon>,
		route: "/clinics",
		component: <Clinics />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Clinic Details",
		key: "clinic-details",
		icon: <Icon fontSize="small">account_balance</Icon>,
		route: "/clinic-details/:id",
		component: <ClinicDetails />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Group Details",
		key: "group-details",
		icon: <Icon fontSize="small">group</Icon>,
		route: "/group-details/:id",
		component: <GroupDetails />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Files",
		key: "case-files",
		icon: <Icon fontSize="small">archive</Icon>,
		route: "/case-files",
		component: <CaseFiles />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case File View",
		key: "case-file-view",
		icon: <Icon fontSize="small">archive</Icon>,
		route: "/case-file-view/:id",
		component: <CaseFileDetails />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Conference Report",
		key: "case-conference-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-conference-report",
		component: <CaseConferenceForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Conference Report Edit",
		key: "case-conference-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-conference-report-edit/:id",
		component: <CaseConferenceForm />, 
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Conference Report Details",
		key: "case-conference-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-conference-report-details/:id",
		component: <CaseConferenceView />, 
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Upload Report",
		key: "case-upload-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-upload-report",
		component: <CaseUploadForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Upload Report Edit",
		key: "case-upload-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-upload-report-edit/:id",
		component: <CaseUploadForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Upload Report Details",
		key: "case-upload-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-upload-report-details/:id",
		component: <CaseUploadView />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Review Report",
		key: "case-review-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-review-report",
		component: <CaseReviewForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Review Report Edit",
		key: "case-review-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-review-report-edit/:id",
		component: <CaseConferenceForm />, 
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Review Report Details",
		key: "case-review-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-review-report-details/:id",
		component: <CaseConferenceForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Detainee Form Report",
		key: "detainee-form-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/detainee-report-form",
		component: <DetaineeForm />, 
		protected: true,
	},
	{
		type: "collapse",
		name: "Detainee Form Report Edit",
		key: "detainee-form-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/detainee-form-report-edit/:id",
		component: <CaseConferenceForm />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Detainee Form Report Details",
		key: "detainee-form-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/detainee-form-report-details/:id",
		component: <DetaineeReportView />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Home",
		key: "home",
		icon: <Icon fontSize="small">home</Icon>,
		route: "/",
		component: <SignIn />,
		protected: false,
	},
	{
		type: "collapse",
		name: "Profile",
		key: "profile",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/profile",
		component: <Profile />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Member Profile",
		key: "member-profile",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/member-profile/:userId",
		component: <MemberProfile />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Password-request",
		key: "password-request",
		icon: <Icon fontSize="small">admin</Icon>,
		route: "/password/request",
		component: <PasswordRequest />,
		protected: false,
	},
	{
		type: "collapse",
		name: "Request Membership",
		key: "request-membership",
		icon: <Icon fontSize="small">group</Icon>,
		route: "/member/request",
		component: <SignUp />,
		protected: false,
	},
	{
		type: "collapse",
		name: "Report",
		key: "report",
		icon: <Icon fontSize="small">group</Icon>,
		route: "/report",
		component: <Report />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Conference Report",
		key: "case-conference-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-conference-report",
		component: <CaseConferenceForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Conference Report Edit",
		key: "case-conference-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-conference-report-edit/:id",
		component: <CaseConferenceForm />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Conference Report Details",
		key: "case-conference-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-conference-report-details/:id",
		component: <CaseConferenceView />, // component to be created
		protected: true,
	},

	{
		type: "collapse",
		name: "Case Upload Report",
		key: "case-upload-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-upload-report",
		component: <CaseUploadForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Upload Report Edit",
		key: "case-upload-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-upload-report-edit/:id",
		component: <CaseUploadForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Upload Report Details",
		key: "case-upload-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-upload-report-details/:id",
		component: <CaseUploadView />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Review Report",
		key: "case-review-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-review-report",
		component: <CaseReviewForm />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Review Report Edit",
		key: "case-review-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-review-report-edit/:id",
		component: <CaseConferenceForm />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Review Report Details",
		key: "case-review-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/case-review-report-details/:id",
		component: <CaseConferenceForm />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Client Intake Report",
		key: "client-intake-form-report",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/detainee-report-form",
		component: <DetaineeForm />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Client Intake Report Edit",
		key: "detainee-form-report-edit",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/detainee-form-report-edit/:id",
		component: <CaseConferenceForm />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Client Intake Report Details",
		key: "detainee-form-report-details",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/detainee-form-report-details/:id",
		component: <CaseConferenceForm />, // component to be created
		protected: true,
	},
	{
		type: "collapse",
		name: "Home",
		key: "home",
		icon: <Icon fontSize="small">home</Icon>,
		route: "/",
		component: <SignIn />,
		protected: false,
	},
	{
		type: "collapse",
		name: "Profile",
		key: "profile",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/profile",
		component: <Profile />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Member Profile",
		key: "member-profile",
		icon: <Icon fontSize="small">person</Icon>,
		route: "/member-profile/:userId",
		component: <MemberProfile />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Password-request",
		key: "password-request",
		icon: <Icon fontSize="small">admin</Icon>,
		route: "/password/request",
		component: <PasswordRequest />,
		protected: false,
	},
	{
		type: "collapse",
		name: "Request Membership",
		key: "request-membership",
		icon: <Icon fontSize="small">group</Icon>,
		route: "/member/request",
		component: <SignUp />,
		protected: false,
	},
	{
		type: "collapse",
		name: "Report",
		key: "report",
		icon: <Icon fontSize="small">group</Icon>,
		route: "/report",
		component: <Report />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Requests",
		key: "requests",
		icon: <Icon fontSize="small">list</Icon>,
		route: "/requests",
		component: <RequestsPage />,
		protected: true,
	},
	{
		type: "collapse",
		name: "PDSS Report",
		key: "pdss-report-form",
		icon: <Icon fontSize="small">report</Icon>,
		route: "/pdss-report-form",
		component: <PdssReportForm />, 
		protected: true,
	},
];

export const sideBarData = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <Icon fontSize="small">dashboard</Icon>,
		route: "/dashboard",
		component: <Dashboard />,
		protected: true,
	},
	{ 
		type: "collapse",
		name: "Analytics",
		key: "analytics",
		icon: <Icon fontSize="small">analytics</Icon>,
		route: "/analytics",
		component: <Analytics />,
		protected: true,
		isAdmin: true,
	},
	{
		type: "collapse",
		name: "Clinics",
		key: "clinics",
		icon: <Icon fontSize="small">account_balance</Icon>,
		route: "/clinics",
		component: <Clinics />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Members",
		key: "members",
		icon: <Icon fontSize="small">group_person</Icon>,
		route: "/members",
		component: <Members />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Requests",
		key: "requests",
		icon: <Icon fontSize="small">list</Icon>,
		route: "/requests",
		component: <RequestsPage />,
		protected: true,
	},
	{
		type: "collapse",
		name: "Case Files",
		key: "case-files",
		icon: <Icon fontSize="small">archive</Icon>,
		route: "/case-files",
		component: <CaseFiles />,
		protected: true,
		isAdmin: false,
	},
	{
		type: "collapse",
		name: "Report",
		key: "report",
		icon: <Icon fontSize="small">score</Icon>,
		route: "/report",
		component: <Report />,
		protected: true,
	},
];
