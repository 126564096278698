"use client"

import { useState, useEffect } from "react"
import {
    Card,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    Box,
    Typography,
    Tooltip,
    InputAdornment,
    Grid,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    styled,
    IconButton,
    Chip,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import VisibilityIcon from "@mui/icons-material/Visibility"
import FilterListIcon from "@mui/icons-material/FilterList"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import MDButton from "components/MDButton"
import { toast } from "react-toastify"
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout"
import { changeUserStatus, getAllUsers } from "services/userService"

// Styled components for consistent UI
const StyledChip = styled(Chip)(({ theme }) => ({
    fontWeight: 500,
    borderRadius: "8px",
    fontSize: "0.75rem",
    height: "24px",
    "&.lawyer": {
        backgroundColor: "rgba(0, 171, 85, 0.1)",
        color: theme.palette.success.main,
    },
    "&.pdss": {
        backgroundColor: "rgba(0, 123, 255, 0.1)",
        color: theme.palette.info.main,
    },
    "&.clinician": {
        backgroundColor: "rgba(255, 171, 0, 0.1)",
        color: theme.palette.warning.main,
    },
}))

// Custom styled table components
const TableContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    overflowX: "auto",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
}))

const Table = styled(Box)({
    width: "100%",
    display: "table",
    borderCollapse: "collapse",
})

const TableHead = styled(Box)({
    display: "table-header-group",
    backgroundColor: "#f5f5f5",
})

const TableBody = styled(Box)({
    display: "table-row-group",
})

const TableRow = styled(Box)(({ hover }) => ({
    display: "table-row",
    "&:hover": hover
        ? {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        }
        : {},
}))

const TableCell = styled(Box)(({ theme, header, align }) => ({
    display: "table-cell",
    padding: "12px 16px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: align || "left",
    verticalAlign: "middle",
    fontSize: "0.875rem",
    ...(header && {
        fontWeight: 600,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[100],
    }),
}))

const roleLabels = {
    Lawyer: "Lawyer",
    PDSS: "PDSS",
    Clinician: "Clinician",
}

function RequestsPage() {
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState("")
    const [filterRole, setFilterRole] = useState("")
    const [filterState, setFilterState] = useState("")
    const [showFilters, setShowFilters] = useState(false)

    // Dialog states
    const [viewDialogOpen, setViewDialogOpen] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState(null)
    const [actionType, setActionType] = useState("")
    const [actionReason, setActionReason] = useState("")
    const [processingAction, setProcessingAction] = useState(false)

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const usersData = await getAllUsers()
                const { data } = usersData.data;

                if (Array.isArray(data)) {
                    const inactiveUsers = data?.filter(user => user.status === "Inactive")
                    setRequests(inactiveUsers)
                } else {
                    console.error("Expected an array but received:", usersData)
                    setRequests([])
                }
            } catch (error) {
                console.error("Error fetching requests:", error)
                setRequests([])
            } finally {
                setLoading(false)
            }
        }

        fetchRequests()
    }, [])

    const filteredRequests = requests.filter((request) => {
        const matchesSearch =
            searchTerm === "" ||
            request.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            request.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            request.phone.includes(searchTerm)

        const matchesRole = filterRole === "" || request.role === filterRole
        const matchesState = filterState === "" || request.state === filterState

        return matchesSearch && matchesRole && matchesState
    })

    const uniqueStates = [...new Set(requests.map((request) => request.state))].sort()

    // Handle view request details
    const handleViewRequest = (request) => {
        setSelectedRequest(request)
        setViewDialogOpen(true)
    }

    // Handle approve/reject action initiation
    const handleActionClick = (request, action) => {
        setSelectedRequest(request)
        setActionType(action)
        setActionReason("")
        setConfirmDialogOpen(true)
    }

    const handleConfirmAction = async () => {
        setProcessingAction(true)

        try {
            await new Promise((resolve) => setTimeout(resolve, 1000))

            if (actionType === "approve") {
                await changeUserStatus(selectedRequest._id, { status: "Active" })
            }

            setRequests((prevRequests) => prevRequests.filter((req) => req.id !== selectedRequest.id))

            const actionMessage =
                actionType === "approve"
                    ? `${selectedRequest.name} has been approved successfully`
                    : `${selectedRequest.name} has been rejected`

            toast.success(actionMessage)

            setConfirmDialogOpen(false)
            setSelectedRequest(null)
            setActionReason("")
        } catch (error) {
            console.error(`Error ${actionType}ing request:`, error)
            toast.error(`Failed to ${actionType} user. Please try again.`)
        } finally {
            setProcessingAction(false)
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat("en-NG", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        }).format(date)
    }

    return (
        <DashboardLayout>
            <MDBox p={3}>
                <MDBox mb={3} display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium">
                        Registration Requests
                    </MDTypography>

                    <MDBox display="flex" gap={2}>
                        <TextField
                            placeholder="Search by name, email or phone"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 300 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <MDButton
                            color="secondary"
                            variant="outlined"
                            onClick={() => setShowFilters(!showFilters)}
                            startIcon={<FilterListIcon />}
                        >
                            Filters
                        </MDButton>
                    </MDBox>
                </MDBox>

                {showFilters && (
                    <MDBox mb={3} p={2} bgcolor="background.paper" borderRadius="lg" boxShadow={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Filter by Role</InputLabel>
                                    <Select value={filterRole} label="Filter by Role" onChange={(e) => setFilterRole(e.target.value)}>
                                        <MenuItem value="">All Roles</MenuItem>
                                        <MenuItem value="lawyer">Lawyer</MenuItem>
                                        <MenuItem value="pdss">PDSS</MenuItem>
                                        <MenuItem value="clinician">Clinician</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Filter by State</InputLabel>
                                    <Select value={filterState} label="Filter by State" onChange={(e) => setFilterState(e.target.value)}>
                                        <MenuItem value="">All States</MenuItem>
                                        {uniqueStates.map((state) => (
                                            <MenuItem key={state} value={state}>
                                                {state}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </MDBox>
                )}

                <Card>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell header>Name</TableCell>
                                    <TableCell header>Contact</TableCell>
                                    <TableCell header>Role</TableCell>
                                    <TableCell header>Location</TableCell>
                                    <TableCell header>Date</TableCell>
                                    <TableCell header align="center">
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={6} sx={{ textAlign: "center", py: 3 }}>
                                            <CircularProgress size={24} />
                                            <Typography variant="body2" sx={{ mt: 1, fontSize: "0.875rem" }}>
                                                Loading requests...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : filteredRequests.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} sx={{ textAlign: "center", py: 3 }}>
                                            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                                No inactive users found
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    filteredRequests.map((request) => (
                                        <TableRow key={request.id} hover>
                                            <TableCell>
                                                <Typography variant="body2" fontWeight="medium" sx={{ fontSize: "0.875rem" }}>
                                                    {request.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                                    {request.email}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary" sx={{ fontSize: "0.75rem" }}>
                                                    {request.phone}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <StyledChip label={roleLabels[request.role]} size="small" className={request.role} />
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                                    {request.state}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary" sx={{ fontSize: "0.75rem" }}>
                                                    {request.lawClinic}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                                    {formatDate(request.createdAt || "")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Box display="flex" justifyContent="center" gap={1}>
                                                    <Tooltip title="View Details">
                                                        <IconButton size="small" onClick={() => handleViewRequest(request)} sx={{ padding: "4px" }}>
                                                            <VisibilityIcon fontSize="small" color="action" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Approve">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleActionClick(request, "approve")}
                                                            sx={{
                                                                padding: "4px",
                                                                color: "#00ab55",
                                                                "&:hover": {
                                                                    backgroundColor: "rgba(0, 171, 85, 0.08)",
                                                                },
                                                            }}
                                                        >
                                                            <CheckCircleIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

                {/* View Request Details Dialog */}
                <Dialog
                    open={viewDialogOpen}
                    onClose={() => setViewDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: "0.75rem",
                            overflow: "hidden",
                        },
                    }}
                >
                    <DialogTitle>
                        <MDTypography fontWeight="medium">
                            Request Details
                        </MDTypography>
                    </DialogTitle>
                    <DialogContent dividers>
                        {selectedRequest && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        {selectedRequest.name}
                                    </MDTypography>
                                    <StyledChip
                                        label={roleLabels[selectedRequest.role]}
                                        size="small"
                                        className={selectedRequest.role}
                                        sx={{ mt: 1 }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <MDTypography variant="subtitle2" color="text.secondary">
                                        Email
                                    </MDTypography>
                                    <MDTypography variant="body2">{selectedRequest.email}</MDTypography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <MDTypography variant="subtitle2" color="text.secondary">
                                        Phone
                                    </MDTypography>
                                    <MDTypography variant="body2">{selectedRequest.phone}</MDTypography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <MDTypography variant="subtitle2" color="text.secondary">
                                        State
                                    </MDTypography>
                                    <MDTypography variant="body2">{selectedRequest.state}</MDTypography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <MDTypography variant="subtitle2" color="text.secondary">
                                        Law Clinic
                                    </MDTypography>
                                    <MDTypography variant="body2">{selectedRequest.lawClinic}</MDTypography>
                                </Grid>

                                <Grid item xs={12}>
                                    <MDTypography variant="subtitle2" color="text.secondary">
                                        Registration Date
                                    </MDTypography>
                                    <MDTypography variant="body2">{formatDate(selectedRequest.createdAt)}</MDTypography>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ padding: "16px 24px" }}>
                        <MDButton onClick={() => setViewDialogOpen(false)} color="secondary">
                            Close
                        </MDButton>
                        <MDButton
                            onClick={() => {
                                setViewDialogOpen(false)
                                handleActionClick(selectedRequest, "approve")
                            }}
                            color="info"
                        >
                            Approve
                        </MDButton>
                    </DialogActions>
                </Dialog>

                {/* Confirm Action Dialog */}
                <Dialog
                    open={confirmDialogOpen}
                    onClose={() => !processingAction && setConfirmDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        sx: {
                            borderRadius: "0.75rem",
                            overflow: "hidden",
                        },
                    }}
                >
                    <DialogTitle>
                        <MDTypography fontWeight="medium">
                            {actionType === "approve" ? "Approve User" : "Reject User"}
                        </MDTypography>
                    </DialogTitle>
                    <DialogContent>
                        {selectedRequest && (
                            <>
                                <MDTypography variant="body1" mb={2}>
                                    {actionType === "approve"
                                        ? `Are you sure you want to approve ${selectedRequest.name}?`
                                        : `Are you sure you want to reject ${selectedRequest.name}?`}
                                </MDTypography>

                                <TextField
                                    label={actionType === "approve" ? "Approval Note (Optional)" : "Reason for Rejection"}
                                    multiline
                                    rows={3}
                                    fullWidth
                                    value={actionReason}
                                    onChange={(e) => setActionReason(e.target.value)}
                                    required={actionType === "reject"}
                                    error={actionType === "reject" && !actionReason}
                                    helperText={actionType === "reject" && !actionReason ? "Please provide a reason for rejection" : ""}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "0.5rem",
                                        },
                                    }}
                                />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ padding: "16px 24px" }}>
                        <MDButton onClick={() => setConfirmDialogOpen(false)} color="secondary" disabled={processingAction}>
                            Cancel
                        </MDButton>
                        <MDButton
                            onClick={handleConfirmAction}
                            color={actionType === "approve" ? "success" : "error"}
                            disabled={processingAction || (actionType === "reject" && !actionReason)}
                        >
                            {processingAction ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : actionType === "approve" ? (
                                "Approve"
                            ) : (
                                "Reject"
                            )}
                        </MDButton>
                    </DialogActions>
                </Dialog>
            </MDBox>
        </DashboardLayout>
    )
}

export default RequestsPage

