import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {
    Grid,
    Card,
    Container,
    Modal,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    MenuItem,
} from "@mui/material";
import { createPdssReport } from "services/pdssService";
import { toast } from "react-toastify";

const PdssReportForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nameOfDutySolicitor: "",
        nameOfPoliceDivision: "",
        nameAndPhoneNumberOfDPO: "",
        nameAndPhoneNumberOfIPO: "",
        nameAndPhoneNumberOfOCLegal: "",
        nameAndPhoneNumberOfDCO: "",
        date: "",
        weeklyObservation: {
            introduction: "",
            details: [
                { day: "Monday", observation: "", detainees: [] },
                { day: "Tuesday", observation: "", detainees: [] },
                { day: "Wednesday", observation: "", detainees: [] },
                { day: "Thursday", observation: "", detainees: [] },
                { day: "Friday", observation: "", detainees: [] },
            ],
        },
    });
    const [openModal, setOpenModal] = useState(false);
    const [currentDayIndex, setCurrentDayIndex] = useState(null);
    const [newDetainee, setNewDetainee] = useState({
        serialNumber: "",
        name: "",
        gender: "",
        offenceCommitted: "",
        dateOfArrest: "",
        age: "",
        chargedToCourt: "",
        lawyerAssigned: "",
        detentionDuration: "",
        externalLawyerAssigned: "",
        bailGranted: "",
        facilitatorOfBail: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleObservationChange = (index, value) => {
        const newDetails = [...formData.weeklyObservation.details];
        newDetails[index] = { ...newDetails[index], observation: value };
        setFormData({
            ...formData,
            weeklyObservation: { ...formData.weeklyObservation, details: newDetails },
        });
    };

    const handleOpenModal = (dayIndex) => {
        setCurrentDayIndex(dayIndex);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewDetainee({
            serialNumber: "",
            name: "",
            gender: "",
            offenceCommitted: "",
            dateOfArrest: "",
            age: "",
            chargedToCourt: "",
            lawyerAssigned: "",
            detentionDuration: "",
            externalLawyerAssigned: "",
            bailGranted: "",
            facilitatorOfBail: "",
        });
    };

    const addDetainee = () => {
        const newDetails = [...formData.weeklyObservation.details];
        newDetails[currentDayIndex].detainees.push(newDetainee);
        setFormData({
            ...formData,
            weeklyObservation: { ...formData.weeklyObservation, details: newDetails },
        });
        handleCloseModal();
    };

    const deleteDetainee = (dayIndex, detaineeIndex) => {
        const newDetails = [...formData.weeklyObservation.details];
        newDetails[dayIndex].detainees.splice(detaineeIndex, 1);
        setFormData({
            ...formData,
            weeklyObservation: { ...formData.weeklyObservation, details: newDetails },
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createPdssReport(formData);
            toast.success("Report created successfully!");
            navigate("/report");
            setFormData({
                nameOfDutySolicitor: "",
                nameOfPoliceDivision: "",
                nameAndPhoneNumberOfDPO: "",
                nameAndPhoneNumberOfIPO: "",
                nameAndPhoneNumberOfOCLegal: "",
                nameAndPhoneNumberOfDCO: "",
                date: "",
                weeklyObservation: {
                    introduction: "",
                    details: [
                        { day: "Monday", observation: "", detainees: [] },
                        { day: "Tuesday", observation: "", detainees: [] },
                        { day: "Wednesday", observation: "", detainees: [] },
                        { day: "Thursday", observation: "", detainees: [] },
                        { day: "Friday", observation: "", detainees: [] },
                    ],
                },
            });
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white" fontWeight="medium" textTransform="capitalize">
                                    PDSS Report Form
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3}>
                                <Container>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            {/* Existing Form Fields (unchanged) */}
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameOfDutySolicitor"
                                                    label="Name of Duty Solicitor"
                                                    fullWidth
                                                    value={formData.nameOfDutySolicitor}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameOfPoliceDivision"
                                                    label="Name of Police Division"
                                                    fullWidth
                                                    value={formData.nameOfPoliceDivision}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfDPO"
                                                    label="Name and Phone Number of DPO"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfDPO}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfIPO"
                                                    label="Name and Phone Number of IPO"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfIPO}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfOCLegal"
                                                    label="Name and Phone Number of OC/Legal"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfOCLegal}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfDCO"
                                                    label="Name and Phone Number of DCO"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfDCO}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="date"
                                                    label="Date"
                                                    type="date"
                                                    fullWidth
                                                    value={formData.date}
                                                    onChange={handleChange}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MDTypography
                                                    variant="h6"
                                                    color="dark"
                                                    fontWeight="medium"
                                                    textTransform="capitalize"
                                                >
                                                    Observations
                                                </MDTypography>
                                                <MDInput
                                                    name="introduction"
                                                    label="Introduction"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    value={formData.weeklyObservation.introduction}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            weeklyObservation: {
                                                                ...formData.weeklyObservation,
                                                                introduction: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Grid>
                                            {formData.weeklyObservation.details.map((detail, index) => (
                                                <Grid item xs={12} sm={6} key={index}>
                                                    <MDTypography variant="subtitle1" fontWeight="medium">
                                                        {detail.day}
                                                    </MDTypography>
                                                    <MDInput
                                                        label={`Observations for ${detail.day}`}
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                        value={detail.observation}
                                                        onChange={(e) => handleObservationChange(index, e.target.value)}
                                                    />
                                                    {detail.detainees.map((detainee, detaineeIndex) => (
                                                        <MDBox
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            mt={1}
                                                            key={detaineeIndex}
                                                        >
                                                            <MDTypography variant="body2">
                                                                {detainee.name || "Unnamed Detainee"}
                                                            </MDTypography>
                                                            <Button
                                                                onClick={() => deleteDetainee(index, detaineeIndex)}
                                                                color="secondary"
                                                                size="small"
                                                            >
                                                                X
                                                            </Button>
                                                        </MDBox>
                                                    ))}
                                                    <MDButton
                                                        onClick={() => handleOpenModal(index)}
                                                        variant="outlined"
                                                        color="info"
                                                        size="small"
                                                        mt={1}
                                                    >
                                                        Add Detainee
                                                    </MDButton>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12}>
                                                <MDButton type="submit" variant="contained" color="info">
                                                    Submit Report
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Container>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />

            {/* Modal for Adding Detainee */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <div style={{ 
                    padding: '20px', 
                    backgroundColor: 'white', 
                    margin: 'auto', 
                    marginTop: '100px', 
                    width: '400px', 
                    maxHeight: '80vh',
                    overflowY: 'auto'
                }}>
                    <MDTypography variant="h6" mb={2}>
                        Add Detainee
                    </MDTypography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MDInput
                                label="S/N"
                                fullWidth
                                value={newDetainee.serialNumber}
                                onChange={(e) => setNewDetainee({ ...newDetainee, serialNumber: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                label="Names of Detainee"
                                fullWidth
                                value={newDetainee.name}
                                onChange={(e) => setNewDetainee({ ...newDetainee, name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                label="Gender of Detainee"
                                fullWidth
                                select
                                value={newDetainee.gender}
                                onChange={(e) => setNewDetainee({ ...newDetainee, gender: e.target.value })}
                                sx={{ paddingVertical: "20px" }}
                            >
                                <MenuItem value="" disabled>
                                    Select Gender
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                            </MDInput>
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                label="Offence Committed"
                                fullWidth
                                value={newDetainee.offenceCommitted}
                                onChange={(e) => setNewDetainee({ ...newDetainee, offenceCommitted: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                label="Date of Arrest"
                                type="date"
                                fullWidth
                                value={newDetainee.dateOfArrest}
                                onChange={(e) => setNewDetainee({ ...newDetainee, dateOfArrest: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                label="Age of Detainee"
                                fullWidth
                                type="number"
                                value={newDetainee.age}
                                onChange={(e) => setNewDetainee({ ...newDetainee, age: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel sx={{ fontSize: "16px" }} component="legend">Was He/She Charged to Court?</FormLabel>
                                <RadioGroup
                                    row
                                    value={newDetainee.chargedToCourt}
                                    onChange={(e) => setNewDetainee({ ...newDetainee, chargedToCourt: e.target.value })}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel sx={{ fontSize: "16px" }} component="legend">Was a Lawyer Assigned to His/Her Case?</FormLabel>
                                <RadioGroup
                                    row
                                    value={newDetainee.lawyerAssigned}
                                    onChange={(e) => setNewDetainee({ ...newDetainee, lawyerAssigned: e.target.value })}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                label="How Long Was the Inmate Detained?"
                                fullWidth
                                value={newDetainee.detentionDuration}
                                onChange={(e) => setNewDetainee({ ...newDetainee, detentionDuration: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel sx={{ fontSize: "16px" }} component="legend">Was Any External Lawyer Assigned?</FormLabel>
                                <RadioGroup
                                    row
                                    value={newDetainee.externalLawyerAssigned}
                                    onChange={(e) =>
                                        setNewDetainee({ ...newDetainee, externalLawyerAssigned: e.target.value })
                                    }
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel sx={{ fontSize: "16px" }} component="legend">Was Bail Granted?</FormLabel>
                                <RadioGroup
                                    row
                                    value={newDetainee.bailGranted}
                                    onChange={(e) => setNewDetainee({ ...newDetainee, bailGranted: e.target.value })}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <MDInput
                                label="Who Facilitated the Bail"
                                fullWidth
                                value={newDetainee.facilitatorOfBail}
                                onChange={(e) => setNewDetainee({ ...newDetainee, facilitatorOfBail: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MDButton onClick={addDetainee} variant="contained" color="info" fullWidth>
                                Add Detainee
                            </MDButton>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </DashboardLayout>
    );
};

export default PdssReportForm;
