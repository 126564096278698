"use client"

import { useState, useEffect } from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    CircularProgress,
    Grid,
    styled,
} from "@mui/material"
import MDButton from "components/MDButton"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { toast } from "react-toastify"
import { getClinicNames } from "services/clinicService"
import { addUser } from "services/userService"

// Custom styled TextField
const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        backgroundColor: theme.palette.grey[100],
        transition: "all 0.2s ease-in-out",
        "& fieldset": {
            borderColor: theme.palette.grey[400],
        },
        "&:hover fieldset": {
            borderColor: theme.palette.info.light,
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.info.main,
            borderWidth: "2px",
        },
    },
    "& .MuiInputLabel-root": {
        color: theme.palette.grey[600], 
        "&.Mui-focused": {
            color: theme.palette.info.main,
        },
    },
    "& .MuiOutlinedInput-input": {
        padding: "12px 14px", 
    },
}))

// Custom styled FormControl for dropdowns
const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        backgroundColor: theme.palette.grey[100],
        transition: "all 0.2s ease-in-out",
        "& fieldset": {
            borderColor: theme.palette.grey[400],
        },
        "&:hover fieldset": {
            borderColor: theme.palette.info.light,
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.info.main,
            borderWidth: "2px",
        },
    },
    "& .MuiInputLabel-root": {
        color: theme.palette.grey[600],
        "&.Mui-focused": {
            color: theme.palette.info.main,
        },
    },
    "& .MuiSelect-select": {
        padding: "12px 14px",
    },
}))

const roles = [
    { value: "Lawyer", label: "Lawyer" },
    { value: "PDSS", label: "PDSS" },
    { value: "Clinician", label: "Clinician" },
]

const nigerianStates = [
    "Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue", "Borno",
    "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "FCT", "Gombe", "Imo",
    "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi", "Kwara", "Lagos", "Nasarawa",
    "Niger", "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba",
    "Yobe", "Zamfara",
]

function RegisterModal({ open, onClose }) {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        role: "",
        state: "",
        lawClinic: "",
    })

    const [lawClinics, setLawClinics] = useState([])
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})

    const fetchLawClinics = async () => {
        try {
            const lawClinicsData = await getClinicNames();
            const { data } = lawClinicsData;
            setLawClinics(data?.map(clinic => ({
                id: clinic._id,
                name: clinic.name
            })));
        } catch (error) {
            console.error("Error fetching law clinics:", error);
            toast.error(error.message || "Failed to fetch law clinics.");
        }
    }
    
    useEffect(() => {
        fetchLawClinics();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }))
        if (errors[name]) {
            setErrors((prev) => ({
                ...prev,
                [name]: "",
            }))
        }
    }

    const validateForm = () => {
        const newErrors = {}
        if (!formData.name.trim()) newErrors.name = "Name is required"
        if (!formData.phone.trim()) newErrors.phone = "Phone is required"
        if (!formData.email.trim()) newErrors.email = "Email is required"
        if (!formData.role) newErrors.role = "Role is required"

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (formData.email && !emailRegex.test(formData.email)) {
            newErrors.email = "Please enter a valid email address"
        }

        if (formData.phone && !/^\d{10,15}$/.test(formData.phone.replace(/[^0-9]/g, ""))) {
            newErrors.phone = "Please enter a valid phone number"
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleSubmit = async () => {
        if (!validateForm()) return
        setLoading(true)

        try {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            
            console.log("Registration data:", formData);

            await addUser(formData);

            toast.success("Registered and pending approval! You'll get an approval email", {
                autoClose: 5000,
            })
            onClose()
            setFormData({ name: "", phone: "", email: "", role: "", state: "", lawClinic: "" })
        } catch (error) {
            console.log(error?.message);
            toast.error(`Registration failed:: ${error?.message}`, { autoClose: 3000 })
            console.error("Registration error:", error)
        } finally {
            setLoading(false)
        }
    }

    // Enhanced dropdown menu styles
    const selectMenuProps = {
        PaperProps: {
            sx: {
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                mt: 1,
                maxHeight: 300, // Consistent max height for all dropdowns
                "& .MuiMenuItem-root": {
                    padding: "10px 16px",
                    fontSize: "0.875rem",
                    "&:hover": {
                        backgroundColor: "rgba(0, 171, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                        backgroundColor: "rgba(0, 171, 255, 0.2)",
                        "&:hover": {
                            backgroundColor: "rgba(0, 171, 255, 0.3)",
                        },
                    },
                },
            },
        },
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    padding: "8px",
                    backgroundColor: "#fff",
                },
            }}
        >
            <DialogTitle>
                <MDTypography fontWeight="medium">
                    Register New Account
                </MDTypography>
            </DialogTitle>
            <DialogContent>
                <MDBox component="form" role="form" mt={2}>
                    <MDBox mb={3}>
                        <StyledTextField
                            name="name"
                            label="Full Name"
                            fullWidth
                            value={formData.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            required
                            variant="outlined"
                        />
                    </MDBox>

                    <Grid container spacing={2} mb={3}>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                name="phone"
                                label="Phone Number"
                                fullWidth
                                value={formData.phone}
                                onChange={handleChange}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                required
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                name="email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                required
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={3}>
                        <Grid item xs={12} md={6}>
                            <StyledFormControl fullWidth error={!!errors.role} required>
                                <InputLabel>Role</InputLabel>
                                <Select
                                    name="role"
                                    value={formData.role}
                                    label="Role"
                                    onChange={handleChange}
                                    MenuProps={selectMenuProps}
                                >
                                    <MenuItem value="" disabled>
                                        Select Role
                                    </MenuItem>
                                    {roles.map((role) => (
                                        <MenuItem key={role.value} value={role.value}>
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
                            </StyledFormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledFormControl fullWidth required>
                                <InputLabel>State</InputLabel>
                                <Select
                                    name="state"
                                    value={formData.state}
                                    label="State"
                                    onChange={handleChange}
                                    MenuProps={selectMenuProps}
                                >
                                    <MenuItem value="" disabled>
                                        Select State
                                    </MenuItem>
                                    {nigerianStates.map((state) => (
                                        <MenuItem key={state} value={state}>
                                            {state}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledFormControl>
                        </Grid>
                    </Grid>

                    <MDBox mb={3}>
                        <StyledFormControl fullWidth>
                            <InputLabel>Law Clinic</InputLabel>
                            <Select
                                name="lawClinic"
                                value={formData.lawClinic}
                                label="Law Clinic"
                                onChange={handleChange}
                                MenuProps={selectMenuProps}
                            >
                                <MenuItem value="" disabled>
                                    Select Law Clinic
                                </MenuItem>
                                {lawClinics.map((clinic) => (
                                    <MenuItem key={clinic.id} value={clinic.id}>
                                        {clinic.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </MDBox>

                    <MDTypography variant="caption" color="text">
                        * Name, Phone, Email & Role are required fields
                    </MDTypography>
                </MDBox>
            </DialogContent>
            <DialogActions sx={{ padding: "16px 24px" }}>
                <MDButton onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </MDButton>
                <MDButton onClick={handleSubmit} color="info" disabled={loading}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Register"}
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

export default RegisterModal
